<template lang="pug">
    v-card.pa-6.mb-5(v-if='!isLoading' style='width:93%;' flat="")
        .d-flex.align-center.justify-space-between.mt-2.mb-6
            v-list-item
                v-list-item-content.pa-0
                    h6.mb-2.d-flex.align-center
                        label(for="recordType" class="font-weight-bold") {{$t("AL_MAIL.TIP")}} :
                        span.ml-1 {{$t("GPT_AINOTEBOOK.CRM_TIP")}}
                    h6.mb-2.d-flex.align-center
                        label(for="recordType" class="font-weight-bold") {{$t('GPT_AINOTEBOOK.RECORD_TYPE')}} :
                        span.ml-1 {{$t('BUSINESS_TYPE.OPPTY')}}
                        //- v-select.pt-0.ml-3(v-model='recordType' :items='RecordTypeField' item-value='key' item-text='name' @change='onRecordTypeChange' :readonly='isDetailReadonly' readonly)
                        //- span(style="font-size:15px;padding-top:12px")
                        p.d-flex.align-center.justify-md-space-between.position-relative.input-has-label.input-has-label-no-border.pl-5(:class='!isEdit ? "remove-arrow" : "" ')
                            label.t-black.ellipsis.pt-1(class='font-weight-bold'  :title='$t("DEAL.PROCESS")') {{$t('DEAL.PROCESS')}} : 
                            v-select.pt-1.ellipsis(solo flat dense v-model='crmAiNoteBookData.business_process_id' :items='stageTypeList' :title='crmAiNoteBookData.business_process_id? stageTypeList.filter((el)=>el.id==crmAiNoteBookData.business_process_id)[0].stage_type_name:""' item-value="id" item-text="stage_type_name" :readonly='!isEdit' :menu-props='{closeOnClick:true}' style='max-width:250px;width:auto;')
                                template(v-slot:append-outer)
                                    v-icon.mr-1.pt-1(size='18' color="green" v-if='isEdit && !isLoading' @click='confirmEditDetail()') icon-check
                                    v-icon.pt-1(size='18' color="red" v-if='isEdit && !isLoading' @click='cancelEditDetail()') mdi-window-close  
                                    v-progress-circular.pt-1(size='18' v-if='isLoading' indeterminate color='primary')
                                    v-icon.pt-1(size='18' v-if='!isEdit && !isLoading' @click='onEdit()') icon-edit
            v-btn.green(@click='showAddFieldDialog' style="color:#FFF" )  
                v-icon(size='15') icon-add 
                span {{$t('ADD_AINOTEBOOK.ADD')}}
        .scrollbar.px-3
            v-container.pa-1.aiNotebook_field_page-container()
                v-data-table.dashboard-table.ellipsis(
                    :headers='OpenHeaders'
                    :items='showList'
                    hide-default-footer
                    :header-props="{sortIcon: 'mdi mdi-arrow-up'}" 
                    height='100%'
                    item-key='id'
                    :loading='isLoading' 
                    :items-per-page="itemPrePage" 
                    :page.sync='page' 
                    @page-count='pageCount=$event' 
                    loading-text="Loading...")
                    template(v-slot:body="{ items }" )
                        draggable(v-model='showList' tag='tbody' @end='updateShowListOrder')
                            tr(v-for='(item, index) in items' :key='index')
                                td
                                    .ellipsis {{item.name}}
                                td 
                                    .ellipsis {{getTypeStr(item.type)}}
                                td
                                    v-btn(icon='icon' @click='deleteField(item)')
                                        v-icon(size='18') icon-remove
                    //         v-data-table(:items='showList' )
                    // template( v-slot:item.column_name="{ item }" )
                    //     .ellipsis
                    //         span {{item.column_name}}
                    // template( v-slot:item.type="{ item }" )
                    //     .ellipsis
                    //         span {{getTypeStr(item.type)}}
                    // template(v-slot:item.action='{ item }')
                    //         .ellipsis
                    //             v-btn(icon='icon' @click='deleteField(item)')
                    //                 v-icon(size='18') icon-remove
                // v-pagination.py-1.mb-3(v-model='page' :length='pageCount' circle :total-visible="10")
        v-dialog(ref='addFieldDialog' v-model='addFieldDialog' width='600' content-class='statusDialog' v-if='addFieldDialog')
            addField-dialog(@emitAddFieldDialog='onEmitAddFieldDialog' :fieldList='fieldList' :showList='showList')           
</template>

<script>
import i18n from '/common/plugins/vue-i18n.js';
import draggable from 'vuedraggable';
import addFieldDialog from "@/components/GPT_Dialog/AiNotebook/addCustomerField";
import UpMeetingService from "@/services/UpMeetingService";
import CustomFieldDataService from "@/services/CustomFieldDataService";
import StageDataService from "@/services/StageDataService";

export default {
    props:{
        ainotebookData :{
            type:Object,
            require:true
        }
    },
    components:{
        addFieldDialog,
        draggable,
    },
    data(){
        return {
            isEdit:false,
            recordType:"",
            addFieldDialog:false,
            target_id : this.$route.params.id,
            aiNotebookFieldItem :[],
            crmAiNoteBookData:null,
            page:1,
            pageCount:0,
            itemPrePage:-1,
            fieldList:[],
            isLoading:false,
            showList:[],
            stageTypeList: [],

          OpenHeaders: [
            {
                text: i18n.t('CUSTOMFIELDS.NAME'),
                align: 'left',
                sortable: false,
                value: 'name',
                width: '45%',
            },
            {
                text: i18n.t('CUSTOMFIELDS.TYPE'),
                align: 'left',
                value: 'type',
                sortable: false,
                width: '45%',
            },
            {
                text: i18n.t('CUSTOMFIELDS.ACTIVE'),
                align: 'left',
                value: 'action',
                sortable: false,
                width: '10%',
            },
          ],
          RecordTypeField: [
          {key: 'opportunity', name: i18n.t('BUSINESS_TYPE.OPPTY') },
          {key: 'case', name: i18n.t('BUSINESS_TYPE.CASE') },
          ],
          originAiNotebookData:null, 
        }
    },
    async created() {
        this.isLoading = true;
        this.crmAiNoteBookData = this.ainotebookData;
        this.recordType = this.crmAiNoteBookData.record_type;
        this.aiNotebookFieldItem = this.crmAiNoteBookData.field_definition[this.recordType];
        await this.getFieldList();
        await this.getShowList();
        await this.getStageType();
        this.isLoading = false;
        // await this.getAinotebookList();
    },
     methods:{
        onEdit(){
            this.isEdit = true;
            this.originAiNotebookData = JSON.parse(JSON.stringify(this.crmAiNoteBookData));
        },
        async confirmEditDetail(){
            this.isLoading = true;
            let fields = [];
            if(this.crmAiNoteBookData.business_process_id == this.originAiNotebookData.business_process_id){
                fields = this.ainotebookData.field_definition.opportunity;
            }else{
                let o_fields = this.ainotebookData.field_definition.opportunity.filter((el)=>el.source_type == "original");
                let cf_fields = this.ainotebookData.field_definition.opportunity.filter((el)=>el.source_type == "custom_field" && el.column_id.includes("_all_"));
                fields = o_fields.concat(cf_fields)
            }
            let edit_data={
                "id":this.ainotebookData.id,
                "record_type":this.ainotebookData.record_type,
                "field_definition":fields,
                "business_process_id":this.crmAiNoteBookData.business_process_id
            }
            UpMeetingService.editAiNotebookFields(edit_data).then((response)=>{
                this.recordType = response.data.record_type;
                this.ainotebookData.field_definition = response.data.field_definition;
                this.ainotebookData.business_process_id = response.data.business_process_id;
                this.aiNotebookFieldItem = response.data.field_definition[this.recordType];
            }).finally(()=>{
                this.getFieldList();
                this.getShowList();
                this.isLoading = false;
            })
            this.isEdit = false;
        },
        cancelEditDetail(){
            this.isEdit = false;
            this.crmAiNoteBookData = this.originAiNotebookData;
        },
       async getStageType(){
            await StageDataService.stageList("deal")
            .then(response => {
                this.stageTypeList = response.data
            })
        },
        getTypeStr(val){
            switch(val){
                case "txt":
                    return "Text";
                case "str":
                    return "String";
                case "num":
                    return "Number";
                case "dat":
                    return "Date";
                case "dtt":
                    return "Date Time";
                case "bol":
                    return "Boolean";
                case "opt":
                    return "Select";
                default:
                    break;
            }
        },
        getShowList(){
            this.showList = [];
            this.aiNotebookFieldItem.forEach((el)=>{
                if(el.source_type == "original"){
                    let field = this.fieldList.filter((item)=>item.column_name==el.column_name);
                    if(field.length>0){
                        this.showList.push(field[0]);
                    }
                }else if(el.source_type == "custom_field"){
                    let field = this.fieldList.filter((item)=>item.column_id==el.column_id);
                    if(field.length>0){
                        this.showList.push(field[0]);
                    }
                }
            })
        },
        async updateShowListOrder(){
            let data = JSON.parse(JSON.stringify(this.showList)).map((el=>{
            delete el.id;
            delete el.name;
            delete el.type;
            return el;
            }))
            let edit_data={
                    "id":this.ainotebookData.id,
                    "record_type":this.ainotebookData.record_type,
                    "field_definition":data,
                    "business_process_id":this.ainotebookData.business_process_id
                }
            await UpMeetingService.editAiNotebookFields(edit_data).then((response)=>{
                this.recordType = response.data.record_type;
                this.ainotebookData.field_definition = response.data.field_definition;
                this.aiNotebookFieldItem = response.data.field_definition[this.recordType];
            }).finally(()=>{
                this.getShowList();
            })
        },
        async getFieldList(){
            // 取得原生欄位
            let crm_fields = [];
            let class_name = "";
            switch(this.crmAiNoteBookData.record_type){
                case "opportunity":
                    class_name = "Opportunity";
                    crm_fields = [
                    { column_name:'expected_close_date',id:'expected_close_date',name: i18n.t('OPPTYSFIELDLIST.expected_close_date') ,type:'dat'},
                    { column_name:'customer_needs',id:'customer_needs',name: i18n.t('OPPTYSFIELDLIST.customer_needs') ,type:'txt'},
                    { column_name:'customer_budget',id:'customer_budget',name: i18n.t('OPPTYSFIELDLIST.customer_budget') ,type:'num'},
                    { column_name:'budget_confirmed',id:'budget_confirmed',name: i18n.t('OPPTYSFIELDLIST.budget_confirmed') ,type:'bol'},
                    ]
                    break;
                default:
                    break;
            }
            crm_fields.forEach((el)=>{
                el.source_type = "original";
                el.record_type = this.crmAiNoteBookData.record_type;
            })
            // 取得自定義欄位
            let bs_id = this.crmAiNoteBookData.business_process_id;
            let cf_fields_list = [];
            if(class_name != ""){
                await CustomFieldDataService.getCustomFieldDetail(class_name).then((response)=>{
                let cf_data = response.data[0].definition ? response.data[0].definition:[];
                let cf_data_list = cf_data.filter(el=>el.stage_type_id==bs_id || el.stage_type_id == "all");
                cf_data_list.forEach((el)=>{
                    for (const [key, value] of Object.entries(el.data)) {
                        if(key.slice(0,3)!="mlt" && key.slice(0,3)!="tst"){
                            cf_fields_list.push({
                                "id":key,
                                "source_type" : "custom_field",
                                "column_id" : key,
                                "column_name": value.label,
                                "name": value.label,
                                "type": key.slice(0,3),
                            })
                        }
                    }
                })
                })
            }
            this.fieldList = crm_fields.concat(cf_fields_list)
        },
        onRecordTypeChange(){
            
        },
       async getAinotebookList(){
        await UpMeetingService.getAiNotebookDeteail(this.target_id).then((response)=>{
            this.aiNotebookFieldItem = response.data.field_definition;
            this.aiNotebookFieldItem.forEach(el=>{
                el.ColumnName = el.column_name;
                el.FormatType = el.format_type;
            })
        }).catch(response => {
            // Redirect to PermissionError page if error status is 403
            if (response.response.status == 403)
            {
                this.$router.replace('/upgpt/PermissionError');
            }
            else if(response.response.status == 404)
            {
                this.$router.replace('/upgpt/PageNotFound');
            }
        })
       },
       deleteField(item){
            let showList = JSON.parse(JSON.stringify(this.showList)).filter(el=>el.id!=item.id);
            let data = showList.map((el=>{
                delete el.id;
                delete el.name;
                delete el.type;
                return el;
            }))
            this.onEmitAddFieldDialog(true,data)
       },
        // dialog
        showAddFieldDialog() {
            this.addFieldDialog = true;
        },
        onEmitAddFieldDialog(val,data){
            if(val){
                let edit_data={
                    "id":this.ainotebookData.id,
                    "record_type":this.ainotebookData.record_type,
                    "field_definition":data,
                    "business_process_id":this.ainotebookData.business_process_id
                }
                UpMeetingService.editAiNotebookFields(edit_data).then((response)=>{
                    this.recordType = response.data.record_type;
                    this.ainotebookData.field_definition = response.data.field_definition;
                    this.aiNotebookFieldItem = response.data.field_definition[this.recordType];
                }).finally(()=>{
                    this.getShowList();
                })
            }
            
            this.addFieldDialog = false;
        }
    }
}
</script>