<template lang="pug">
    v-card.pa-6.mb-5(v-else style='width:93%;' flat="")
        .d-flex.align-center.justify-space-between.mt-2.mb-6
            h6.mb-2.d-flex.align-center
                label(for="recordType" class="font-weight-bold") {{$t("AL_MAIL.TIP")}}:
                span.ml-3 {{$t("GPT_AINOTEBOOK.AI_NOTE_TIP")}}
            v-btn.green(@click='showAddDialog' style="color:#FFF" )  
                v-icon(size='15') icon-add 
                span {{$t('ADD_AINOTEBOOK.ADD')}} 
        .scrollbar.px-3
            v-container.pa-1.aiNotebook_field_page-container
                v-data-table.dashboard-table.ellipsis(
                    :headers='OpenHeaders' 
                    :items='aiNotebookFieldItem' 
                    :items-per-page="itemPrePage" 
                    hide-default-footer
                    :header-props="{sortIcon: 'mdi mdi-arrow-up'}" 
                    :page.sync='page' 
                    @page-count='pageCount=$event' 
                    height='100%'
                    item-key='column_id'
                    :loading='isLoading' 
                    loading-text="Loading..."
                )
                    template(v-slot:body="{ items }" )
                        draggable(tag='tbody' v-model='aiNotebookFieldItem' @end='updateAinotebookList')
                            tr(v-for='(item, index) in items' :key='index') 
                                td 
                                    .ellipsis {{item.column_name}}
                                td 
                                    .ellipsis {{item.description}}
                                td
                                    .ellipsis {{ getTypeStr(item.column_id.slice(0,3)) }}
                                td
                                    v-btn(icon='icon' @click='onEdit(item)')
                                        v-icon(size='18') icon-edit
                                    v-btn(icon='icon' @click='onDelete(item)')
                                        v-icon(size='18') icon-remove
                    //- template(v-slot:item.column_name ='{ item }')
                    //-     .ellipsis
                    //-         span {{ item.column_name }}
                    //- template( v-slot:item.description="{ item }" )
                    //-     .ellipsis
                    //-         span {{ item.description }}
                    //- template( v-slot:item.column_id="{ item }" )
                    //-     .ellipsis
                    //-         span {{getTypeStr(item.column_id.slice(0,3))}}
                    //- template(v-slot:item.action='{ item }')
                    //-     .ellipsis
                    //-         v-btn(icon='icon' @click='onEdit(item)')
                    //-             v-icon(size='18') icon-edit
                    //-         v-btn(icon='icon' @click='onDelete(item)')
                    //-             v-icon(size='18') icon-remove
                //- v-pagination.py-1.mb-3(v-model='page' :length='pageCount' circle :total-visible="10")
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitMessageDialog(false)' @keydown.enter='onEmitMessageDialog(true)')
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
        v-dialog(v-model='editDialog' width='400' content-class='statusDialog' v-if='editDialog')
            edit-dialog(@emitEditDialog='onEmitEditDialog' :fieldData='editItem')
        v-dialog(v-model='addDialog' width='400' content-class='statusDialog' v-if='addDialog')
            add-dialog(@emitAddDialog='onEmitAddDialog' :maxKey='maxKey' :showList='showList')
</template>

<script>
import i18n from '/common/plugins/vue-i18n.js';
import draggable from 'vuedraggable';
import addDialog from "@/components/GPT_Dialog/AiNotebook/addAiNotebookField";
import EditDialog from "@/components/GPT_Dialog/AiNotebook/editAiNotebookField"
import UpMeetingService from "@/services/UpMeetingService";
import messageDialog from "@/components/Dialog/messageDialog";

export default {
    props:{
        ainotebookData :{
            type:Object,
            require:true
        }
    },
    components:{
        addDialog,
        EditDialog,
        messageDialog,
        draggable,
    },
    data(){
        return {
            isDelete:false,
            isEdit:false,
            isDetailReadonly:false,
            recordType:"",
            addFieldDialog:false,
            aiNotebookFieldItem :[],
            crmAiNoteBookData:null,
            page:1,
            pageCount:0,
            itemPrePage:-1,
            fieldList:[],
            isLoading:false,
            showList:[],
            maxKey:null,
            deleteItem:null,
            editItem:null,

            addDialog:false,
            editDialog: false,
            messageDialog: false,
            message: "",
            target_id : this.$route.params.id,

          OpenHeaders: [
            {
                text: i18n.t('CUSTOMFIELDS.NAME'),
                align: 'left',
                sortable: false,
                value: 'column_name',
                width: '30%',
            },
            {
                text: i18n.t('CUSTOMFIELDS.DESCRIPTIONS'),
                align: 'left',
                sortable: false,
                value: 'description',
                width: '30%',
            },
            {
                text: i18n.t('CUSTOMFIELDS.TYPE'),
                align: 'left',
                value: 'column_id',
                sortable: false,
                width: '30%',
            },
            {
                text: i18n.t('CUSTOMFIELDS.ACTIVE'),
                align: 'left',
                value: 'action',
                sortable: false,
                width: '10%',
            },
          ]  
        }
    },
    async created() {
        this.isLoading = true;
        this.crmAiNoteBookData = this.ainotebookData;
        this.recordType = this.crmAiNoteBookData.record_type;
        this.aiNotebookFieldItem = this.crmAiNoteBookData.field_definition[this.recordType];
        this.showList = this.aiNotebookFieldItem;
        this.maxKey = this.crmAiNoteBookData.field_definition["ai_notes_max_key"]
        this.isLoading = false;
    },
     methods:{
        getTypeStr(val){
            switch(val){
                case "txt":
                    return "Text";
                case "str":
                    return "String";
                case "num":
                    return "Number";
                case "dat":
                    return "Date";
                case "dtt":
                    return "Date Time";
                case "bol":
                    return "Boolean";
                case "opt":
                    return "Select";
                default:
                    break;
            }
        },
        deleteField(){
            let showList = JSON.parse(JSON.stringify(this.showList)).filter(el=>el.column_id!=this.deleteItem.column_id);
            let data = {
                ai_notes:showList,
                ai_notes_max_key:this.maxKey
            }
            this.onEmitAddDialog(true,data)
       },
       onDelete(item){
        this.deleteItem = item;
        this.isDelete = true;
        this.message = i18n.t("ADD_AINOTEBOOK.DELETE_ERROR");
        this.messageDialog = true;
       },
        async onEmitMessageDialog(val){
            if(val && this.isDelete){
                this.deleteField();
            }
            this.deleteItem = null;
            this.isDelete = false;
            this.deleteId = null;
            this.messageDialog = false;
        },
        // dialog
        onEmitAddDialog(val,data){
            if(val){
                let edit_data={
                    "id":this.crmAiNoteBookData.id,
                    "record_type":this.crmAiNoteBookData.record_type,
                    "field_definition":data,
                    "business_process_id":this.crmAiNoteBookData.business_process_id
                }
                UpMeetingService.editAiNotebookFields(edit_data).then((response)=>{
                    this.recordType = response.data.record_type;
                    this.ainotebookData.field_definition = response.data.field_definition;
                    this.aiNotebookFieldItem = response.data.field_definition[this.recordType];
                    this.showList = this.aiNotebookFieldItem;
                    this.maxKey = response.data.field_definition["ai_notes_max_key"];
                })
            }
            this.addDialog = false;
        },
        onEdit(item){
            this.editItem = item;
            this.editDialog = true;
        },
        onEmitEditDialog(val,data){
            if(val){
                this.ainotebookData.field_definition.ai_notes = this.ainotebookData.field_definition.ai_notes.filter((el)=>el.column_id != data.column_id);
                this.ainotebookData.field_definition.ai_notes.unshift(data);
                let edit_data={
                    "id":this.crmAiNoteBookData.id,
                    "record_type":this.crmAiNoteBookData.record_type,
                    "field_definition":this.ainotebookData.field_definition,
                    "business_process_id":this.crmAiNoteBookData.business_process_id
                }
                UpMeetingService.editAiNotebookFields(edit_data).then((response)=>{
                    this.recordType = response.data.record_type;
                    this.ainotebookData.field_definition = response.data.field_definition;
                    this.aiNotebookFieldItem = response.data.field_definition[this.recordType];
                    this.showList = this.aiNotebookFieldItem;
                    this.maxKey = response.data.field_definition["ai_notes_max_key"];
                })
            }
            this.editDialog = false;
        },

        async getAinotebookList(){
            await UpMeetingService.getAiNotebookDeteail(this.target_id).then((response)=>{
                // let data = response.data;
                this.aiNotebookFieldItem = response.data.field_definition;
                // this.aiNotebookFieldItem.forEach(el=>{
                //     el.ColumnName = el.column_name;
                //     el.FormatType = el.format_type;
                // })
            }).catch(response => {
                // Redirect to PermissionError page if error status is 403
                if (response.response.status == 403)
                {
                    this.$router.replace('/upgpt/PermissionError');
                }
                else if(response.response.status == 404)
                {
                    this.$router.replace('/upgpt/PageNotFound');
                }
            })
        },
        async updateAinotebookList(){  
                let field_definition_data = {
                    "ai_notes": this.aiNotebookFieldItem,
                    "ai_notes_max_key": this.ainotebookData.field_definition.ai_notes_max_key,
                    "opportunity": this.ainotebookData.field_definition.opportunity,
                }
                let edit_data={
                    "id":this.crmAiNoteBookData.id,
                    "record_type":this.crmAiNoteBookData.record_type,
                    "field_definition": field_definition_data,
                    "business_process_id":this.crmAiNoteBookData.business_process_id
                }
                await  UpMeetingService.editAiNotebookFields(edit_data).then((response)=>{
                    this.recordType = response.data.record_type;
                    this.ainotebookData.field_definition = response.data.field_definition;
                    this.aiNotebookFieldItem = response.data.field_definition[this.recordType];
                    this.showList = this.aiNotebookFieldItem;
                    this.maxKey = response.data.field_definition["ai_notes_max_key"];
                })
        },
        // dialog
        showAddDialog() {
            this.addDialog = true;
        },
    },
    watch:{
        "messageDialog":function(){
            if(!this.messageDialog){
                this.isDelete = false;
            }
        }    
    }
}
</script>