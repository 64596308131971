<template lang="pug">
    #ManageUsers    
        loadingOverlay(v-if='loading' message='Loading . . .' :zIndex='999')
        .px-4.py-3
            v-row.align-center(no-gutters)
                v-col(cols='7')
                    v-btn-toggle(v-model.lazy='mangeUserShow' color='primary accent-3' exclusive group @change='changeActive')
                        v-btn.rounded-card.mx-0(:value='true' height='40' :readonly='mangeUserShow') {{$t('MANAGEUSERS.ACTIVATED')}}({{ activeUser }})
                        v-btn.rounded-card.mx-0(:value='false' height='40' :readonly='mangeUserShow') {{$t('MANAGEUSERS.UNACTIVATED')}}({{ unactiveUser }})
                v-col(cols='5').d-flex
                    v-text-field.pa-0.pt-1.mr-4(v-model='searchMangeUser' flat dense height='30' min-height='30' prepend-inner-icon='icon-search')
                    v-btn(@click='showAddUserDialog' height='40' color='green' depressed dark)
                        v-icon.mr-1(size='14') icon-add
                        span {{$t('MANAGEUSERS.INVITE_USERS')}} 
            v-data-table.dashboard-table(
                :headers='mangeUserHeaders' 
                :items='mangeUserListData' 
                :items-per-page='itemPerPage' 
                :page.sync='page' 
                :item-class="rowClass"
                fixed-header 
                @page-count='pageCount = $event' 
                :search='searchMangeUser' 
                multi-sort
                max-height='70vh'
                height='70vh'
                :header-props="{sortIcon: 'mdi mdi-arrow-up'}"  
                hide-default-footer='hide-default-footer' 
                dense
            )
                template(v-slot:item.is_service_agent='{ item }')
                    v-checkbox.v-input__slot_checkbox.v-input__slot(v-model="item.usersetting.is_service_agent" hide-details="auto" @change='isActiveAgent(item)') 
                template(v-slot:item.fullName='{ item }')
                    v-list-item
                        v-list-item-avatar.mb-1(color='aliceBlue' size='40')
                            v-img( v-if='item.userPhoto!=null' :src='item.userPhoto')
                            v-icon(v-else) mdi-account
                        v-list-item-content
                            v-row(no-gutters)
                                v-col.text-left(cols='8')
                                    v-list-item-title {{ item.fullName }}
                template(v-slot:item.is_staff='{ item }')
                    span(v-if='item.is_staff === true') {{$t('MANAGEUSERS.MANAGER')}}  
                    span(v-else) {{$t('MANAGEUSERS.STAFF')}} 
                template(v-slot:item.last_login='{ item }')
                    span(v-if='item.last_login') {{ new Date(item.last_login).toLocaleString() }}
                template(v-slot:item.active='{ item }')
                    v-btn(icon @click='showEditUserDialog(item)')
                        v-icon(size='18') icon-edit
                    v-btn(icon @click='onDelete(item)' v-if='item.is_active')
                        v-icon(size='20') mdi-cancel
                    v-btn(icon @click='onReactive(item)' v-else)
                        v-icon(size='20') mdi-refresh
                v-pagination.py-1.mb-3(v-model='page' :length='pageCount' circle :total-visible="10")
        v-dialog(v-model='mangeUserAddDialog' max-width='500' content-class='setting-dialog' v-if='mangeUserAddDialog')
            v-card.mangeUserCard
                v-card-title.d-flex.justify-center.mb-4 {{$t('MANAGEUSERS.INVITE_USERS')}} 
                v-card-text
                    v-form(v-model='addUser.valid' ref='form')
                        v-row(no-gutters)    
                            v-col.mt-5(cols='12')
                                v-text-field.v-text-field__slot.height-58(v-model='addUser.username' :label=`$t('MANAGEUSERS.MAIL')`, solo prepend-icon="mdi-email" :rules="formRule.emailRules")             
                        v-row(no-gutters)  
                            v-col.pr-2(cols='6')                           
                                v-text-field.v-text-field__slot.height-58(v-model='addUser.last_name' :label=`$t('MANAGEUSERS.LAST_NAME')`, solo :rules="formRule.nameRules",prepend-icon='mdi-rename-box')
                            v-col.pl-2(cols='6')
                                v-text-field.v-text-field__slot.height-58(v-model='addUser.first_name' :label=`$t('MANAGEUSERS.FIRST_NAME')`, solo :rules="formRule.firstNameRules",)
                        v-row(no-gutters)  
                            v-col(cols='12')
                                v-select.v-text-field__slot.height-58(v-model='addUser.is_staff' :items="manageRoleList" item-value="value" item-text="text", :label=`$t('MANAGEUSERS.USER_ROLE')`, solo prepend-icon='mdi-account-multiple' )
                    v-card-actions.mt-8.px-0.py-0
                        v-row(no-gutters)
                            v-col.pr-2(cols='6')
                                v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
                            v-col.pl-2(cols='6')
                                v-btn(:ripple='false' @click='onUserAdd(addUser)' width='100%' height='40' color='success' depressed dark) {{$t('MANAGEUSERS.INVITE')}}
        v-dialog(v-model='mangeUserEditDialog' max-width='500' content-class='setting-dialog')
            v-card.mangeUserCard
                v-card-title.d-flex.justify-center.mb-4 {{$t('MANAGEUSERS.EDIT_USER')}} 
                v-card-text
                    v-form(v-model='editUser.valid' ref='form')
                        v-row(no-gutters).mt-5  
                            v-col.pr-2(cols='6')                           
                                v-text-field.v-text-field__slot.height-58(v-model='editUser.last_name' :label=`$t('MANAGEUSERS.LAST_NAME')`, solo :rules="formRule.nameRules",prepend-icon='mdi-rename-box')
                            v-col.pl-2(cols='6')
                                v-text-field.v-text-field__slot.height-58(v-model='editUser.first_name' :label=`$t('MANAGEUSERS.FIRST_NAME')`, solo :rules="formRule.firstNameRules",)
                        v-row(no-gutters)  
                            v-col(cols='12')
                                v-select.v-text-field__slot.height-58(v-model='editUser.is_staff' :items="manageRoleList" item-value="value" item-text="text", :label=`$t('MANAGEUSERS.USER_ROLE')`, solo prepend-icon='mdi-account-multiple' )
                        v-row(no-gutters)    
                            v-col(cols='12')
                                v-text-field.v-text-field__slot.height-58(v-model='editUser.email' :label= `$t('GENERAL.MAIL')`, solo prepend-icon="mdi-email" :rules="formRule.emailRules")             
                    v-card-actions.mt-8.px-0.py-0
                        v-row(no-gutters)
                            v-col.pr-2(cols='6')
                                v-btn(:ripple='false' @click='mangeUserEditDialog = false' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
                            v-col.pl-2(cols='6')
                                v-btn(:ripple='false' @click='onUserEdit(editUser)' width='100%' height='40' color='success' depressed dark) {{$t('GENERAL.SAVE')}}
        
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
                message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
        v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
                success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
                error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
    import Vue from 'vue';
    import messageDialog from '@/components/Dialog/messageDialog';    
    import PersonalSettingService from "@/services/PersonalSettingService";
    import successDialog from "@/components/Dialog/successDialog";
    import errorDialog from "@/components/Dialog/errorDialog";
    import loadingOverlay from "@/components/Common/loadingOverlay";    
    import passData from "@/services/passData.js";
    import i18n from '/common/plugins/vue-i18n.js' 

    export default Vue.extend({
        props: {  
            
        },
        components: {  
            loadingOverlay,            
            messageDialog,
            successDialog,
            errorDialog,
        },        
        data() {
        return {        
            loading:false,    
            activeUser: 0,
            unactiveUser: 0,
            isSuperUser:false,
            reActiveData:{},
            reActiveItemIndex:null,
            reActiveUserID:null,
            reActiveUser:false,
            deleteData:{},
            deleteItemIndex:null,
            deleteUserID:null,
            deleteUser:false,
            userId:null,
            isStaff:false,
            editData:{},
            defultRole:{value:false,text:i18n.t('MANAGEUSERS.STAFF')},
            manageRoleList:[
                {value:true,text:i18n.t('MANAGEUSERS.MANAGER')},
                {value:false,text:i18n.t('MANAGEUSERS.STAFF')}
            ],
            mangeUserListData: [],
            page: 1,
            pageCount: 1,
            itemPerPage: 100,
            mangeUserHeaders: [
                {  text: i18n.t('MANAGEUSERS.NAME'), value: 'fullName', align: 'left'},
                {  text: i18n.t('MANAGEUSERS.ROLE'), value: 'is_staff', align: 'left'},
                {  text: i18n.t('MANAGEUSERS.IS_SERVICE_AGENT'), value: 'is_service_agent', align: 'left' },
                {  text: i18n.t('MANAGEUSERS.LAST_TIME'), value: 'last_login', align: 'left'},
                {  text: i18n.t('MANAGEUSERS.ACTIVE'), value: 'active', align: 'left',width: '150px', sortable: false,},
            ],
            searchMangeUser: '',
            mangeUserAddDialog: false,
            addUser : {
                valid: true,
                username:'',
                first_name: '',
                last_name: '',
                is_staff: true,
                email: '',
            },
            mangeUserEditDialog: false,
            editUser : {
                valid: true,                
                firs_name: '',
                last_name: '',
                is_staff: '',
                email: '',
            },			
            mangeUserShow: true,
            errorMessage:"",
            message:"",
            messageDialog:false,
            errorDialog:false,
            successDialog:false,
            valid:false,            
            userData:{},
            max_license:null,
            formRule: {
            fieldRules: [(v) => !!v || i18n.t('RULE.RULE_R')],
            accountRules: [(v) => !!v || i18n.t('RULE.RULE_R_ACCOUNT')],
            nameRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R_LASTNAME_3'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
            firstNameRules: [(v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')],
            emailRules: [(v) => !!v && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|(^$)|null/.test(v) || i18n.t('RULE.RULE_EMAIL')],
            phoneRules: [(v) => /^09\d{8}$|(^$)|null/.test(v) || i18n.t('RULE.RULE_PHONE')],
      },
        }},
        async created()
        {
            await PersonalSettingService.getCurrentUserDetail()
            .then(response => 
            {  
                this.userId = response.data[0].id;                
            });

            await PersonalSettingService.getUserDetail()
            .then(response => 
            {
                if (response.data.userList.length > 0) {
                    this.isStaff = response.data.userList.filter(item=>{return item.id == this.userId})[0].is_staff
                    this.isSuperUser = response.data.userList.filter(item=>{return item.id == this.userId})[0].is_superuser
                    this.max_license = response.data.max_license;
                    this.mangeUserData = response.data.userList;
                    // this.mangeUserListData = response.data;
                    this.mangeUserListData = this.mangeUserData.filter(el => {return el.is_active == true });
                    this.activeUser = this.mangeUserListData.length
                    this.unactiveUser = this.mangeUserData.length - this.activeUser
                    this.mangeUserData.forEach(item => {
                        item.fullName = item.last_name + ' ' + item.first_name                            
                    });                
                    for(var i in this.mangeUserListData)
                    {
                        this.mangeUserListData[i].is_service_agent = this.mangeUserListData[i].usersetting.is_service_agent;
                        if(this.mangeUserListData[i].usersetting.photo != null)
                        {
                            this.isUserPhoto = true
                            this.mangeUserListData[i].userPhoto = this.mangeUserListData[i].usersetting.photo                    
                        }
                    }
                }
            })
            .catch((response) => {
                this.showErrorDialog(response);
            });
        },
        methods:{
            validateForm(){
                this.valid = this.$refs.form.validate();
                if(this.valid)                    
                    return true;
                else
                    return false;
            },
            showSuccessDialog() {
                this.successDialog = true;
            },
            onEmitSuccessDialog() {
                this.successDialog = false;
             },
            showErrorDialog(message) {  
                this.errorDialog = true;      
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            showMessageDialog(message) {
                this.messageDialog = true;
                this.message = message;
            },
            onEmitMessageDialog(val) {
                this.messageDialog = false; 
                if(val){
                if(this.deleteUser == true){
                    this.changeData=this.mangeUserListData.filter(user=>{return user.id==this.deleteUserID})[0]
                    PersonalSettingService.updateUserDetail(this.deleteData).then(()=>{                        
                        this.mangeUserListData.splice(this.deleteItemIndex,1)
                        this.mangeUserData.find(el => el == this.changeData).is_active = false;     
                        this.activeUser = this.activeUser-1;
                        this.unactiveUser = this.unactiveUser +1; 
                    })                    
                    this.showSuccessDialog()
                    this.deleteUser = false
                    this.deleteData={}
                }
                if(this.reActiveUser == true){
                    this.changeData=this.mangeUserListData.filter(user=>{return user.id==this.reActiveUserID})[0]
                    PersonalSettingService.updateUserDetail(this.reActiveData).then(()=>{                        
                        this.mangeUserListData.splice(this.reActiveItemIndex,1)
                        this.mangeUserData.find(el => el == this.changeData).is_active = true;     
                        this.activeUser = this.activeUser +1;
                        this.unactiveUser = this.unactiveUser -1; 
                    })                    
                    this.showSuccessDialog()
                    this.reActiveUser = false
                    this.reActiveData = {}
                }
                }
            }, 
            changeActive(val) {                
            if (val) {
                this.mangeUserListData = this.mangeUserData.filter(el => {return el.is_active == true });                
            } else if(val!=undefined) {
                this.mangeUserListData = this.mangeUserData.filter(el => {return el.is_active == false });
            }
            },
            showAddUserDialog() {
            if(this.activeUser>=this.max_license){
            // if(this.activeUser>=100){
                this.showErrorDialog(i18n.t('MANAGEUSERS.ERROR'))
                return 
            }
            this.mangeUserAddDialog = true;
        },
        async onUserAdd(data) {
            if(this.isStaff === false)
            {
                this.showErrorDialog(i18n.t('MANAGEUSERS.ERROR_2'))
                return
            }            
            if(this.mangeUserData.find(el=>el.username == data.username))
            {
                this.showErrorDialog(i18n.t('MANAGEUSERS.ERROR_3'))
                return
            }            
            if(this.validateForm()){
                this.loading = true
                data.email = data.username
                let lang = window.localStorage.getItem('language');
                await PersonalSettingService.createUserDetail(data,lang).then((response)=>{                  
                if(response.data.last_name)         
                {
                    response.data.fullName = response.data.last_name+' '+response.data.first_name
                    this.mangeUserData.splice(0,0, response.data) 
                    this.mangeUserListData.splice(0,0, response.data)            
                    this.activeUser = this.activeUser +1;
                    this.showSuccessDialog()
                }
                else{
                    this.showErrorDialog(i18n.t('MANAGEUSERS.ERROR'))
                }
                })
                this.mangeUserAddDialog = false;
                this.loading = false
                this.addUser =  {
                    valid: true,
                    username:'',
                    first_name: '',
                    last_name: '',
                    is_staff: false,
                    email: '',
                }            
            }
        },
        onCancel(){
            this.mangeUserAddDialog = false
            this.addUser =  {
                valid: true,
                username:'',
                first_name: '',
                last_name: '',
                is_staff: false,
                email: '',
            }
            
        },
        showEditUserDialog(data) {              
            if(this.isStaff === false)
            {
                this.showErrorDialog(i18n.t('MANAGEUSERS.ERROR_2'))
                return
            }
            this.mangeUserEditDialog = true;
            this.editUser.first_name = data.first_name;
            this.editUser.last_name = data.last_name;
            this.editUser.email = data.email;
            this.editUser.is_staff = data.is_staff
            this.editUser.id = data.id
        },
        async onUserEdit(data) {
            if(this.validateForm()){
                this.changeData=this.mangeUserListData.filter(user=>{return user.id==data.id})[0]
                await PersonalSettingService.updateUserDetail(data)
                .then((response)=>{
                    let editUserData = this.mangeUserData.find(el => el == this.changeData)
                    editUserData.fullName = response.data.last_name+' '+response.data.first_name
                    editUserData.is_staff = response.data.is_staff
                    this.changeData.last_name = response.data.last_name
                    this.changeData.first_name = response.data.first_name
                    this.changeData.email = response.data.email
                    this.changeData.is_staff = response.data.is_staff
                })
                this.mangeUserEditDialog = false;
                let user = JSON.parse(window.localStorage.getItem('user'));
                if( user.userid == data.id && !data.is_staff)
                {
                    window.location.reload();
                }
            }	
        },
        onMail: function (mail) {
            return 'mailto:'+mail;
        },
        rowClass() {        
            const rowClass = 'dataTableColor'
            return rowClass;
        },
        onDelete (item) {
            if(this.isStaff === false)
            {
                this.showErrorDialog(i18n.t('MANAGEUSERS.ERROR_2'))
                return
            }
            this.showMessageDialog(i18n.t('MANAGEUSERS.MESSAGE'));
            this.deleteUser =true
            this.deleteUserID = item.id
            this.deleteItemIndex =  this.mangeUserListData.indexOf(item);
            this.deleteData = {id:item.id,is_active:false}
        },
        onReactive(item){
            if(this.isStaff === false)
            {
                this.showErrorDialog(i18n.t('MANAGEUSERS.ERROR_2'))
                return
            }
            this.showMessageDialog(i18n.t('MANAGEUSERS.MESSAGE_2'));
            this.reActiveUser = true
            this.reActiveUserID = item.id
            this.reActiveItemIndex =  this.mangeUserListData.indexOf(item);
            this.reActiveData = {id:item.id,is_active:true}            
        },
        isActiveAgent(item){
            item.usersetting.status = 'OFFLINE';
            PersonalSettingService.updateDetail(item.id, item)
            .then(response => {
                // get data from local storage
                let userJson = window.localStorage.getItem('user');
                let user = JSON.parse(userJson);

                if(user.userid == response.data.id){
                    // set data to local storage
                    user.is_service_agent = response.data.usersetting.is_service_agent;
                    user.status = response.data.usersetting.status;
                    window.localStorage.setItem('user', JSON.stringify(user));
                    passData.$emit("menuBarAgentData", user);
                }
            })
            .catch(response => {
                this.showErrorDialog(response);
            })
        }
        }
    });
</script>